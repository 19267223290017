import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Modal from './home/v3/Modal';
import {
  colors, mobileThresholdPixels, StyledTitle2 as Title2,
} from './home/v3/styledComponents';

const ModalTitle = styled(Title2)`
  width: 400px;
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 25px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 80%;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 10px;
  }
`;

const ModalP = styled.p`
  font-size: 18px;
  line-height: 28px;
  color: ${colors.navy};
  font-family: Roboto;
  width: 425px;
  text-align: center;
  margin: 0px;
  ${({ hasMarginBottom }) => hasMarginBottom && 'margin-bottom: 15px;'}

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
    margin: 0px 0px 10px;
    width: 85%;

  }
`;

const Modal3DS = ({ closeModal }) => (
  <Modal closeModal={closeModal} hasCross modalWidth={785}>
    <ModalTitle>Sécurisation des transactions en ligne</ModalTitle>
    <ModalP hasMarginBottom>Afin que vos transactions en ligne soient sécurisées, vous allez être redirigé vers votre banque.</ModalP>
    <ModalP>Une fois que vous avez validé la transaction avec votre banque, merci de cliquer sur le bouton “revenir vers le site marchand”</ModalP>
  </Modal>
);

Modal3DS.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default Modal3DS;
