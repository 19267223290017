import React from 'react';

import Layout from '../../layouts/index';
import { Container, Content, StepContainer } from '../../components/home/v3/styledComponents';
import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import LastStep from '../../components/order/LastStep';
import PartnersPager from '../../components/home/v3/PartnersPager';

const Step4 = () => (
  <Layout routeSlug="Step4">
    <Container>
      <MenuBar showGiftBanner={false} />
      <Content hasNoBanner>
        <StepContainer>
          <LastStep />
        </StepContainer>
      </Content>
      <PartnersPager linen />
      <Footer />
    </Container>
  </Layout>
);

export default Step4;
