import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import routesMap from '../../Routes';

import { pushToLayer } from '../../services/googleTagManager';
import {
  registerCard, isCardTypeValid, isCardNumberValid, errorMessages, getBrowserInfo,
} from '../../services/mangopay';
import callApi from '../../services/api';
import withOrderContext from '../../withOrderContext';
import withCustomerContext from '../../withCustomerContext';

import { mobileThresholdPixels, Button as ButtonV3, colors } from '../home/v3/styledComponents';
import CreditCard from './CreditCard';
import CreditCardsSelector from './CreditCardsSelector';
import Dots from '../home/v3/Dots';

import logoMangopay from '../../assets/Mangopay2.png';
import logoMMA from '../../assets/mma.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 20px;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.column && 'flex-direction: column; margin-top: 70.5px;'}
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom}px;`}
  font-family: Libre Baskerville;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  text-align: center;
  @media (max-width: ${mobileThresholdPixels}) {
    align-items: flex-start;
    ${(props) => props.column && 'margin-top: 30px; align-items: center;'}
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 30px;
  }
`;

const GuaranteesContainer = styled.div`
  display: flex;
  font-size: 14px;
  margin-top: 15px;
  align-items: baseline;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 0px;
    margin-bottom: 15px;
    align-items: flex-start;
  }
`;

const P = styled.p`
  font-size: 18px;
  line-height: 28px;
  margin: 0px 0px 23px;
  ${(props) => props.bold && 'font-weight: 500;'}
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
    margin: 0px 0px 10px;
  }
`;

const SubTitle = styled.div`
  font-family: Libre Baskerville;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 20px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
  }
`;

const Logo = styled.img`
  height: 17px;
  width: auto;
  margin-right: ${(props) => props.marginRight}px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-right: ${(props) => props.marginRight - 6}px;
  }
`;

const Button = styled(ButtonV3)`
  width: 245px;
  padding: 3px 15px 0px;
  margin-top: 59px;
  font-size: 18px;
  ${(props) => props.marginRight && 'margin-right: 45px;'}
  ${(props) => !props.isAvailable && `
    background-color: ${colors.lightGrey3};
    color: ${colors.white};
    pointer-events: none;
    cursor: unset;
    border-color: ${colors.lightGrey3};`} 
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 13px;
    width: 187px;
    min-width: unset;
    margin: 25px auto 0px;
    ${(props) => props.mobileSmallMarginTop && 'margin-top: 10px;'}
  }
`;

const CreditCardInfosContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const CreditCardForm = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ErrorText = styled.p`
  font-family: Roboto;
  line-height: 20px;
  font-size: 14px;
  color: ${colors.error};
  @media (max-width: ${mobileThresholdPixels}) {
    line-height: 17px;
    font-size: 12px;
  }
`;

const trackCardSaved = () => {
  pushToLayer({
    event: 'Card Saved',
    context: 'during-order',
  }, true);
};

class CreditCardInfos extends React.Component {
  constructor(props) {
    super(props);
    const { customerContext: { customer } } = this.props;
    this.state = {
      customerHasCards: customer && customer.cards && customer.cards.length > 0,
      cardName: '',
      cardNumber: '',
      cvv: '',
      selectedCardIndex: 0,
      isAddNewCardSelected: false,
    };
  }

  setCreditCardInputValue = (input, value) => {
    if (input === 'cardNumber') this.validateCardNumber(value);
    this.setState({ [input]: value, formError: false });
  }

  validateCardNumber = (cardNumber) => {
    const strippedCardNumber = cardNumber.replace(/ /g, '');
    if (!isCardTypeValid(strippedCardNumber)) {
      this.setState({
        cardError: 'Les cartes American Express (Amex) ne sont pas supportées.',
      });
    } else if (
      strippedCardNumber
      && strippedCardNumber.length > 15
      && !isCardNumberValid(strippedCardNumber)
    ) {
      this.setState({
        cardError: 'Oups, une erreur s’est glissée. Êtes-vous sûr du numéro ?',
      });
    } else {
      this.setState({ cardError: null });
    }
  }

  selectCard = (selectedCardIndex) => {
    this.setState({ selectedCardIndex });
  }

  addNewCard = () => {
    this.setState({ isAddNewCardSelected: true });
  }

  isValid = () => {
    const {
      expiryMonth, expiryYear, cvv, cardNumber,
    } = this.state;
    return (
      expiryMonth
      && expiryYear
      && cvv
      && cardNumber
    );
  }

  redirect3DS = (redirectUrl) => {
    const { setShow3DSModal } = this.props;
    if (redirectUrl) {
      setShow3DSModal(redirectUrl);
      window.onbeforeunload = null;
    } else {
      navigate(routesMap.SuccessPayment.url);
    }
  }

  submitCard = (isCardNew = false) => {
    const { orderContext: { orderId }, customerContext: { customer } } = this.props;
    this.setState({
      error: false, errorType: undefined, isLoading: true,
    });
    sessionStorage.setItem('customer', JSON.stringify(customer));
    if (isCardNew) {
      if (!this.isValid()) {
        return this.setState({ formError: true, isLoading: false });
      }
      const {
        expiryMonth, expiryYear, cvv, cardNumber, cardId,
      } = this.state;
      const expiryDate = `${expiryMonth}${expiryYear}`;
      const cardData = {
        expiryDate, cvv, cardNumber, cardId,
      };
      return registerCard(cardData, customer._id)
        .then((card) => {
          const BrowserInfo = getBrowserInfo();
          return callApi('public/submitCard', 'post', { card, orderId, BrowserInfo });
        })
        .then((res) => { trackCardSaved(); this.redirect3DS(res.redirectUrl); })
        .catch((response) => {
          this.setState({ error: true, isLoading: false, errorType: response.error });
          if (typeof Raven !== 'undefined') {
            Raven.captureException(JSON.stringify(response)); // eslint-disable-line
          } else {
            console.error(response);
          }
        });
    }
    const { selectedCardIndex } = this.state;
    const BrowserInfo = getBrowserInfo();
    return callApi('public/submitCard', 'post', { card: { CardId: customer.cards[selectedCardIndex].id }, orderId, BrowserInfo })
      .then((res) => { trackCardSaved(); this.redirect3DS(res.redirectUrl); });
  }

  renderCreditCardForm = () => {
    const {
      cardName, cardNumber, cvv, cardError, isLoading,
    } = this.state;
    return (
      <CreditCardForm>
        <CreditCard
          onChange={this.setCreditCardInputValue}
          cardName={cardName}
          cardNumber={cardNumber}
          cvv={cvv}
          error={cardError}
        />
        <GuaranteesContainer alignItems="center" row marginBottom="0">
          <Logo marginRight={12} src={logoMangopay} alt="Logo Mangopay" />
          Règlement 100% sécurisé via Mangopay
        </GuaranteesContainer>
        <GuaranteesContainer marginLeft justifyContent="left" row marginsMobile alignItems="center">
          <Logo marginRight={15} src={logoMMA} alt="Logo MMA" />
          Vos vêtements sont garantis par notre partenaire MMA
        </GuaranteesContainer>
        <Button isAvailable={!isLoading} navy onClick={this.submitCard}>Enregistrer ma carte</Button>
        {this.renderError()}
      </CreditCardForm>
    );
  }

  renderCreditCardFormContainer = () => (
    <CreditCardInfosContainer>
      <SubTitle>Caution bancaire</SubTitle>
      <P>
        On ne prélève rien tout de suite, le paiement n‘aura lieu qu‘après validation du devis en rdv.
        Ces informations nous permettent de sécuriser le déplacement de votre couturier
      </P>
      {this.renderCreditCardForm()}
    </CreditCardInfosContainer>
  )

  renderError = () => {
    const { error, errorType, formError } = this.state;
    const message = (errorType && errorMessages[errorType]) || 'Une erreur s’est produite...';
    return (
      <>
        {error && <ErrorText>{message}</ErrorText>}
        {formError && (
          <ErrorText>Merci de remplir tous les champs</ErrorText>
        )}
      </>
    );
  }

  render() {
    const {
      selectedCardIndex, isAddNewCardSelected, customerHasCards,
    } = this.state;
    const { customerContext: { customer } } = this.props;
    return (
      <Container>
        <Header column marginBottom={31}>
          {!customerHasCards && 'Renseignez votre carte dès maintenant'}
          {customerHasCards && 'Quelle carte souhaitez-vous utiliser pour cette commande ?'}
          <Dots marginTop={5} noMargin />
        </Header>

        <div>
          {(!customer || !customer.cards || customer.cards.length === 0)
            && this.renderCreditCardFormContainer()}

          {customer && customer.cards && customer.cards.length > 0 && (
            <CreditCardsSelector
              cards={customer.cards}
              selectedCardIndex={selectedCardIndex}
              isAddNewCardSelected={isAddNewCardSelected}
              selectCard={(index) => this.selectCard(index)}
            />
          )}

          {customer && customer.cards
            && customer.cards.length > 0 && !isAddNewCardSelected
            && (
              <ButtonsContainer>
                <Button isAvailable navy marginRight onClick={() => this.submitCard(true)}>Choisir cette carte</Button>
                <Button isAvailable transparent mobileSmallMarginTop onClick={this.addNewCard}>Ajouter une carte</Button>
              </ButtonsContainer>
            )}
          {isAddNewCardSelected && this.renderCreditCardForm()}
        </div>
      </Container>
    );
  }
}

CreditCardInfos.propTypes = {
  orderContext: PropTypes.shape({
    orderId: PropTypes.string,
  }).isRequired,
  customerContext: PropTypes.shape({
    customer: PropTypes.shape({
      _id: PropTypes.string,
      cards: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
      })),
    }),
  }).isRequired,
  setShow3DSModal: PropTypes.func.isRequired,
};

export default withCustomerContext(withOrderContext(CreditCardInfos));
