import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';

import { getActiveSlots, getGroupedSlotsPerDay } from '../../services/slotsFormatting';
import withOrderContext from '../../withOrderContext';
import withCustomerContext from '../../withCustomerContext';

import {
  colors, mobileThresholdPixels,
  StyledTitle2 as Title2,
} from '../home/v3/styledComponents';
import CreditCardInfos from './CreditCardInfos';
import PasswordForm from './PasswordForm';
import logoMagnifyingGlass from '../../assets/magnifyingGlass.png';
import Modal3DS from '../Modal3DS';

const Container = styled.div`
  width: 100%;
  @media (max-width: ${mobileThresholdPixels}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 7px;
    width: auto;
  }
`;

const ComponentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.navy};
  font-family: Roboto;
  margin-top: 80px;
  background-color: ${colors.white};
  border-top: solid 8px ${colors.yellow};
  box-shadow: 0px 1px 6px ${colors.blueGrey};
  padding: 52px 142px 61px;
  @media (max-width: ${mobileThresholdPixels}) {
    padding: 25px 20px 30px;
    margin-top: 34px;
  }
`;

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16.5px;
  width: 100%;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 20px;
  }
`;

const ComponentHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.column && 'flex-direction: column; margin-top: 70.5px;'}
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom}px;`}
  font-family: Libre Baskerville;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  text-align: center;
  @media (max-width: ${mobileThresholdPixels}) {
    align-items: flex-start;
    ${(props) => props.column && 'margin-top: 30px; align-items: center;'}
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 30px;
  }
`;

const Img = styled.img`
  margin: 0px 20px 0px -31px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 2px 0px 0px;
  }
`;

const EmptyDiv = styled.div`
  margin-left: 31px;
  height: 31px;
`;

const P = styled.p`
  font-size: 18px;
  line-height: 28px;
  margin: 0px 0px 23px;
  ${({ bold }) => bold && 'font-weight: 500;'}
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
    margin: 0px 0px 10px;
  }
`;

const Line = styled.div`
  position: relative;
  top: -1px;
  height: 1px;
  width: 692px;
  background-color:  ${colors.lightGrey2};
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

class LastStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show3DSModal: false,
    };
  }

  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    const { orderContext: { initialize } } = this.props;
    initialize();
  }

  setShow3DSModal = (redirectUrl) => {
    const { show3DSModal, redirectUrlState } = this.state;
    this.setState({ show3DSModal: !show3DSModal });
    if (redirectUrl) {
      this.setState({ redirectUrlState: redirectUrl });
      setTimeout(() => {
        this.setState({ show3DSModal: false });
        window.location = redirectUrl;
      }, 5000);
    }
    if (redirectUrlState) {
      window.location = redirectUrlState;
    }
  }

  render() {
    const { orderContext: { slots } } = this.props;
    const { customerContext: { customer = {} } } = this.props;
    const { isShadowAccount } = customer;
    const { show3DSModal } = this.state;
    const activeSlots = getActiveSlots(slots);
    const groupedSlotsPerDay = getGroupedSlotsPerDay(activeSlots);
    const landingPage = typeof localStorage !== 'undefined' ? localStorage.getItem('landingPage') : undefined;
    return (
      <Container>
        {show3DSModal && <Modal3DS closeModal={this.setShow3DSModal} />}
        <Title2>Merci d’avoir pris rendez-vous avec Tilli !</Title2>
        <ComponentsContainer>
          <ComponentContainer>
            <ComponentHeader marginBottom={40}>
              <Img src={logoMagnifyingGlass} alt="À la recherche du meilleur couturier" />
              <div>À la recherche du meilleur couturier</div>
              <EmptyDiv />
            </ComponentHeader>
            <P>
              Vous avez demandé un rendez-vous
              {groupedSlotsPerDay.map((groupedSlots) => groupedSlots.slots[0].begin)
                .reduce((acc, slot, index, slotsList) => {
                  let dateLine = '';
                  if (acc === '') dateLine = ' le';
                  else if (index === slotsList.length - 1) dateLine = ' ou le';
                  else dateLine = ', le';
                  return `${acc}${dateLine} ${moment(slot).format('dddd D MMMM')}`;
                }, '')}
              .
            </P>
            <P>
              On vous cherche un couturier sur-mesure pour répondre à vos besoins.
              Vous recevrez par mail son nom et son heure exacte d’arrivée.
            </P>
            <P>À très vite ! :)</P>
            <P>L’équipe Tilli</P>
          </ComponentContainer>
          <Line />
          {landingPage !== 'Advice' && <CreditCardInfos setShow3DSModal={this.setShow3DSModal} />}
          {isShadowAccount && (<PasswordForm />)}
        </ComponentsContainer>
      </Container>
    );
  }
}

LastStep.propTypes = {
  orderContext: PropTypes.shape({
    slots: PropTypes.shape({}),
    initialize: PropTypes.func,
  }).isRequired,
  customerContext: PropTypes.shape({
    customer: PropTypes.shape({
      isShadowAccount: PropTypes.bool,
    }),
  }).isRequired,
};

export default withCustomerContext(withOrderContext(LastStep));
